import '@fontsource/inter/500.css'

import { extendTheme } from '@chakra-ui/react'
import { menuAnatomy, tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const Menu = createMultiStyleConfigHelpers(menuAnatomy.keys)
const Table = createMultiStyleConfigHelpers(tableAnatomy.keys)

const theme = extendTheme({
  breakpoints: {
    base: '0em',
    sm: '48em',
    md: '48em',
    lg: '48em',
  },
  colors: {
    primary: "#121212",
    secondary: "#949494",
    background: "#ffffff"
  },
  fonts: {
    body: "Inter, sans-serif",
    heading: "Inter, sans-serif",
    mono: "Menlo, monospace",
  },
  fontSizes: {
    xs: "18px",
    sm: "20px",
    md: "24px",
    lg: "42px",
    xl: "80px",
    "2xl": "140px",
  },
  textStyles: {
    h1: {
      fontFamily: 'heading',
      fontSize: ['xl', '2xl'],
      lineHeight: '100%',
      letterSpacing: '-3%',
      color: '#121212',
    },
    h2: {
      fontFamily: 'body',
      fontSize: ['24px', '42px'],
      lineHeight: '120%',
      letterSpacing: '-3%',
      color: '#121212',
    },
    body: {
      fontFamily: 'body',
      fontSize: ['18px', '20px'],
      fontWeight: 'normal',
      lineHeight: '150%',
      letterSpacing: '-3%',
      color: '#121212',
    }
  },
  semanticTokens: {
    colors: {
      "chakra-body-bg": { _light: "white", _dark: "black" },
      "chakra-body-text": { _light: "black", _dark: "white" },
      // "chakra-border-color": { _light: "gray.200", _dark: "whiteAlpha.300" },
      // "chakra-inverse-text": { _light: "white", _dark: "gray.800" },
      // "chakra-subtle-bg": { _light: "gray.100", _dark: "gray.700" },
      // "chakra-subtle-text": { _light: "gray.600", _dark: "gray.400" },
      // "chakra-placeholder-color": { _light: "gray.500", _dark: "whiteAlpha.400" },
    },
  },
  radii: {
    none: "0",
    sm: "0",
    base: "0",
    md: "0",
    lg: "0",
    xl: "0",
    "2xl": "0",
    "3xl": "0",
    full: "9999px",
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  components: {
    Menu: Menu.defineMultiStyleConfig({
      baseStyle: Menu.definePartsStyle({
        list: {
          border: 'none',
          borderRadius: 0,
        }
      })
    }),
    Table: Table.defineMultiStyleConfig({
      baseStyle: Table.definePartsStyle({
        tr: {
          borderTopWidth: '1px',
          borderTopColor: 'primary',
          borderBottomWidth: '1px',
          borderBottomColor: 'primary',
        },
        th: {
          borderTopWidth: '1px',
          borderTopColor: 'primary',
          borderBottomWidth: '1px',
          borderBottomColor: 'primary',
        },
        td: {
          borderTopWidth: '1px',
          borderTopColor: 'primary',
          borderBottomWidth: '1px',
          borderBottomColor: 'primary',
        }
      })
    }),
  },
});

export default theme;